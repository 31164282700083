import React, { ErrorInfo } from 'react';
import Link from 'next/link';
import { NextSeo } from 'next-seo';
import { NextRouter, withRouter } from 'next/router';
import { I18n, TFunction, withTranslation } from 'next-i18next';
import { Button } from '@wbk/ui';
import Layout from '@/components/layout';
import { logger } from '../../../logger.config';
import DeflatedBallWrapper from './DeflatedBallWrapper';

type Props = React.PropsWithChildren<{ t: TFunction; i18n: I18n; router: NextRouter }>;
type State = {
  hasError: boolean;
  eventId?: string;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true, eventId: logger.lastId() };
  }

  showReportDialog = () => {
    logger.showReportDialog({
      eventId: this.state.eventId || '',
      lang: this.props.i18n.language,
      title: this.props.t('common:errors.boundry_error'),
      subtitle: this.props.t('common:errors.boundry_error_description'),
      subtitle2: '',
      labelClose: this.props.t('common:cancel'),
      labelSubmit: this.props.t('common:errors.send_report'),
      labelComments: this.props.t('common:errors.boundry_error_label_comments'),
      successMessage: this.props.t('common:errors.send_report_success'),
    });
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.showReportDialog();
    error.name = '[Fatal] ErrorBoundary';
    error.message = error.message || 'No message attached';
    logger.captureException(error, {
      extra: { errorInfo },
      level: 'fatal',
      tags: { ErrorBoundary: true },
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.router.pathname !== prevProps.router.pathname) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <NextSeo title={this.props.t('common:errors.boundry_error')} />
          <Layout>
            <DeflatedBallWrapper>
              <section className='mx-auto max-w-lg rounded-lg bg-secondary-400/80 px-6 py-10'>
                <h1 className='mb-6 text-center text-3xl capitalize'>
                  <strong>{this.props.t('common:errors.boundry_error').toString()}</strong>
                </h1>
                <p>{this.props.t('common:errors.boundry_error_description').toString()}</p>
                <div className='mx-auto max-w-xs'>
                  <Button className='mt-6 w-full text-lg' onClick={this.showReportDialog}>
                    {this.props.t('common:errors.send_report')}
                  </Button>
                  <Button
                    as={Link as React.ElementType}
                    shape='outlined'
                    href='/'
                    className='mt-6 w-full text-lg'
                  >
                    {this.props.t('common:go_home')}
                  </Button>
                </div>
              </section>
            </DeflatedBallWrapper>
          </Layout>
        </>
      );
    }

    return this.props.children;
  }
}

export default withTranslation('common')(withRouter(ErrorBoundary));
