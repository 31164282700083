import Image from 'next/image';
import Reveal from '../animation/Reveal';

type Props = {
  topTitle?: string | React.ReactNode;
  children: React.ReactNode;
};

const DeflatedBallWrapper = ({ topTitle, children }: Props) => {
  return (
    <div className='relative px-4 py-14 before:absolute before:inset-0 before:-z-10 before:bg-black/70'>
      <Image
        className='-z-20 object-cover object-[50%_60%]'
        src='/images/grass-corner.jpg'
        fill
        sizes='1280px'
        alt=''
        priority
      />
      <div className='container flex min-h-[70vh] flex-col items-center lg:flex-row'>
        <div className='lg:grow'>
          {topTitle}
          <Image
            className='mx-auto w-full max-w-2xl'
            src='/images/deflated-ball.png'
            width={800}
            height={600}
            alt='Deflated ball'
          />
        </div>
        <Reveal direction='bottom' className='mx-auto w-full grow lg:w-auto'>
          {children}
        </Reveal>
      </div>
    </div>
  );
};

export default DeflatedBallWrapper;
