import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { WbkLogo } from '@wbk/svg';
import Link from 'next/link';

const Copyright = () => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  return (
    <div className='flex flex-wrap items-center justify-center gap-4'>
      <span className='text-center text-xs text-white'>
        {t('common:footer.rights', { year: new Date().getFullYear() })}
      </span>
      <div className='flex items-center gap-3'>
        <span className='text-xs text-white'>{t('common:footer.powered')}</span>
        <Link href={`https://webook.com/${locale}`} target='_blank' rel='noreferrer'>
          <WbkLogo width={58} height={33} className='transition hover:scale-110' />
        </Link>
      </div>
    </div>
  );
};

export default Copyright;
