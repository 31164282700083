import { motion } from 'framer-motion';

type Props = {
  children: React.ReactNode;
};

const MenuItem = ({ children }: Props) => {
  return (
    <motion.li
      variants={{
        open: {
          y: 0,
          opacity: 1,
          transition: {
            y: { stiffness: 1000, velocity: -100 },
          },
        },
        closed: {
          y: 50,
          opacity: 0,
          transition: {
            y: { stiffness: 1000 },
          },
        },
      }}
    >
      {children}
    </motion.li>
  );
};

export default MenuItem;
