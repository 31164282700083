import { useMemo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Button } from '@wbk/ui';
import { createCookie } from '@wbk/utils';

const LanguageSwitch = () => {
  const { locale, locales, asPath } = useRouter();
  const { t, i18n } = useTranslation();

  const handleDir = (goto: string) => {
    createCookie({
      name: 'lang',
      value: goto,
      expiration: 30,
    });
    document.documentElement.dir = i18n.dir(goto);
  };

  const otherLangs = useMemo(() => {
    return (
      locales?.filter((l) => {
        return l !== locale && l !== 'default';
      }) || []
    );
  }, [locale, locales]);

  if (otherLangs?.length < 1) return null;

  return (
    <Button
      as={Link}
      href={encodeURI(asPath)}
      locale={otherLangs[0]}
      theme='white'
      shape='text'
      onClick={() => handleDir(otherLangs[0])}
      data-testid='lang-switcher'
      data-location='header'
      data-target-language={otherLangs[0]}
      role='menuitem'
      className='!px-1.5 !py-1.5 md:!px-2.5'
    >
      {t(`common:${otherLangs[0]}`)}
    </Button>
  );
};

export default LanguageSwitch;
