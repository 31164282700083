import { useEffect } from 'react';
import { useCookieConsent } from '@wbk/analytics';

const CookieConsent = () => {
  const { showConsent, acceptConsent } = useCookieConsent();

  useEffect(() => {
    if (!showConsent) return;

    acceptConsent();
    // eslint-disable-next-line
  }, [showConsent]);

  return null;
};

export default CookieConsent;
