import { SocialProfileJsonLd } from 'next-seo';

export default function SocialJsonLd() {
  return (
    <SocialProfileJsonLd
      type='Organization'
      name='Al-Ittihad tickets'
      url='https://tickets.ittihadclub.sa/'
      sameAs={[
        'https://x.com/ittihad',
        'https://www.instagram.com/ittihadclub.sa',
        'https://www.snapchat.com/add/snap.ittihad',
        'https://www.youtube.com/c/ittihadclub',
      ]}
    />
  );
}
