export const SOCIAL_MEDIA = [
  {
    name: 'twitter',
    en: 'https://twitter.com/ittihad',
    ar: 'https://twitter.com/ittihad',
    icon: '/icons/social/twitter.svg',
  },
  {
    name: 'instagram',
    en: 'https://www.instagram.com/ittihadclub.sa/',
    ar: 'https://www.instagram.com/ittihadclub.sa/',
    icon: '/icons/social/instagram.svg',
  },
  {
    name: 'youtube',
    en: 'https://www.youtube.com/c/ittihadclub',
    ar: 'https://www.youtube.com/c/ittihadclub',
    icon: '/icons/social/youtube.svg',
  },
  {
    name: 'snapchat',
    en: 'https://www.snapchat.com/add/snap.ittihad',
    ar: 'https://www.snapchat.com/add/snap.ittihad',
    icon: '/icons/social/snapchat.svg',
  },
];
