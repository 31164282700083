import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import Image from 'next/image';
import { Button } from '@wbk/ui';

const LoginButton = () => {
  const { locale: lang, asPath } = useRouter();
  const { t } = useTranslation();

  return (
    <Button
      as={Link}
      href={`/${lang}/login?redirect=${asPath}`}
      theme='primary'
      shape='outlined'
      data-testid='login-profile'
      data-location='header'
      className='w-full !px-1.5 !py-1.5 md:!px-2.5'
      role='menuitem'
    >
      <p className='hidden sm:block'>{t('common:auth.login_signup')}</p>
      <div className='block shrink-0 rounded-full md:hidden'>
        <Image
          className='shrink-0'
          src='/icons/profile/user-p.svg'
          width={20}
          height={20}
          alt='user icon'
        />
      </div>
    </Button>
  );
};

export default LoginButton;
