import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-hot-toast';

export default function OfflineState() {
  const { t } = useTranslation();

  useEffect(() => {
    const offline = () => {
      toast.error(t('common:network_off'), { duration: 60 * 1000 * 60 });
    };
    const online = () => {
      toast.remove();
    };
    window.addEventListener('offline', offline);
    window.addEventListener('online', online);

    return () => {
      window.removeEventListener('online', online);
      window.removeEventListener('offline', offline);
    };
  }, [t]);

  return <></>;
}
