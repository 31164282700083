import { useMemo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import LanguageSwitch from '@/components/common/LanguageSwitch';
import HideOnWebview from '@/components/webview/HideOnWebview';
import { HEADER_NAV } from './nav';
import UserProfileButton from './UserProfileButton';

type Props = {
  settings: LayoutProps['header'];
};

const DesktopHeader = ({ settings }: Props) => {
  const { t } = useTranslation();
  const { locale, pathname } = useRouter();

  const menu = useMemo(() => HEADER_NAV.filter((n) => n.title !== 'support_privacy'), []);

  if (settings?.type === 'mini') {
    return (
      <div className='bg-body-level-1'>
        <nav className='container hidden items-center justify-between py-3 lg:flex'>
          <Link
            href='/'
            className='relative block shrink-0 hover:opacity-90'
            data-location='header'
            role='menuitem'
          >
            <Image
              className='h-12 object-contain'
              src='/icons/logo.svg'
              height={48}
              width={48}
              alt={t('common:seo.title')}
            />
          </Link>
          <div className='flex items-center justify-end gap-2 text-sm xl:gap-2 xl:text-base'>
            <LanguageSwitch />
          </div>
        </nav>
      </div>
    );
  }

  if (settings?.type === 'booking') {
    return (
      <HideOnWebview>
        <nav className='container flex items-center justify-between py-3'>
          <Link
            href='/'
            className='relative block shrink-0 hover:opacity-90'
            data-location='header'
            role='menuitem'
          >
            <Image
              className='h-12 object-contain'
              src='/icons/logo.svg'
              height={48}
              width={48}
              alt={t('common:seo.title')}
            />
          </Link>

          <div className='flex items-center justify-end gap-2 text-sm xl:gap-2 xl:text-base'>
            <LanguageSwitch />
          </div>
        </nav>
      </HideOnWebview>
    );
  }

  return (
    <div className='bg-body-level-2'>
      <nav className='container hidden items-center justify-between bg-body-level-2 py-3 lg:flex'>
        <ul
          className='flex items-center gap-4 text-sm xl:gap-6 xl:text-base rtl:text-xs xl:rtl:text-base'
          role='menu'
        >
          <li role='presentation'>
            <Link
              href='/'
              className='relative block shrink-0 hover:opacity-90'
              data-location='header'
              role='menuitem'
            >
              <Image
                className='h-12 object-contain'
                src='/icons/logo.svg'
                height={48}
                width={48}
                alt={t('common:seo.title')}
              />
            </Link>
          </li>
          {settings?.type === 'main' &&
            menu.map((link, i) => {
              const path = link[`href_${locale}` as 'href_en'] || link.href_en;
              return (
                <li key={i} role='presentation'>
                  <Link
                    href={link.external ? path : link.href_en}
                    className={`flex items-center gap-2 whitespace-nowrap border-b-2 border-primary p-1 uppercase transition hover:border-primary hover:text-primary focus:border-primary focus:text-primary focus:outline-none ${pathname === path ? 'text-primary' : 'border-transparent'}`}
                    data-title={link.title}
                    data-location='header'
                    role='menuitem'
                  >
                    {t(`common:nav.${link.title}`)}
                  </Link>
                </li>
              );
            })}
        </ul>

        <ul className='flex items-center gap-1 text-sm xl:gap-4 xl:text-base' role='menu'>
          <li className='[&>a]:text-xs [&>a]:xl:text-base [&>a]:ltr:lg:text-sm' role='presentation'>
            <LanguageSwitch />
          </li>
          {['main'].includes(String(settings?.type)) && (
            <li
              className='[&>a]:!py-1 [&>a]:text-xs [&>a]:xl:text-base [&>a]:ltr:lg:text-sm'
              role='presentation'
            >
              <UserProfileButton />
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default DesktopHeader;
