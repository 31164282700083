import Image from 'next/image';
import { APP_STORE_URL, GOOGLE_PLAY_STORE_URL } from '@/src/constants/apps';

const AppsDownload = () => {
  return (
    <div className='flex flex-row items-center gap-2 xl:gap-4'>
      <a
        className={`${!APP_STORE_URL ? 'cursor-not-allowed opacity-20' : 'hover:opacity-80'}`}
        href={APP_STORE_URL || undefined}
        target='_blank'
        rel='noopener noreferrer'
      >
        <Image src='/images/app/app-store.svg' alt='appstore' width={105} height={35} />
      </a>

      <a
        className={`${
          !GOOGLE_PLAY_STORE_URL ? 'cursor-not-allowed opacity-20' : 'hover:opacity-80'
        }`}
        href={GOOGLE_PLAY_STORE_URL || undefined}
        target='_blank'
        rel='noopener noreferrer'
      >
        <Image src='/images/app/play-store.svg' alt='playstore' width={112} height={34} />
      </a>
    </div>
  );
};

export default AppsDownload;
