import { ApiConfig } from '@wbk/api';

ApiConfig.init({
  wbk: {
    api: process.env.NEXT_PUBLIC_TICKETS_API_BASE!,
    authApi: process.env.NEXT_PUBLIC_AUTH_API_BASE!,
    apiToken: process.env.NEXT_PUBLIC_TICKETS_API_TOKEN!,
    appSource: process.env.NEXT_PUBLIC_APP_SOURCE!,
    paymentApi: process.env.NEXT_PUBLIC_TICKETS_PAYMENT_API_BASE!,
    ticketingDomain: process.env.NEXT_PUBLIC_BASE_URL!,
  },
  grecaptcha: {
    v3Key: process.env.NEXT_PUBLIC_CAPTCHA_KEY!,
  },
});
