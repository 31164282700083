export const FOOTER_NAV = [
  {
    title: 'about',
    href_en: 'https://ittihadclub.sa/about',
    href_ar: 'https://ittihadclub.sa/about',
    external: true,
  },
  {
    title: 'store',
    href_en: 'https://store.ittihadclub.sa',
    href_ar: 'https://store.ittihadclub.sa',
    external: true,
  },
  {
    title: 'news',
    href_en: 'https://ittihadclub.sa/news',
    href_ar: 'https://ittihadclub.sa/news',
    external: true,
  },
  {
    title: 'contact_us',
    href_en: 'https://webook.com/en/contact-us',
    href_ar: 'https://webook.com/ar/contact-us',
    external: true,
  },
];

export const FOOTER_SUB_NAV = [
  {
    name: 'cookie_policy',
    href_en: '#',
    href_ar: '#',
  },
  {
    name: 'terms_and_conditions',
    href_en: '#',
    href_ar: '#',
  },
  {
    name: 'privacy_policy',
    href_en: '#',
    href_ar: '#',
  },
];
