export const HEADER_NAV = [
  {
    title: 'tickets',
    href_en: '/',
    href_ar: '/',
  },
  {
    title: 'about',
    href_en: 'https://ittihadclub.sa/about',
    href_ar: 'https://ittihadclub.sa/about',
    external: true,
  },
  {
    title: 'store',
    href_en: 'https://store.ittihadclub.sa',
    href_ar: 'https://store.ittihadclub.sa',
    external: true,
  },
  {
    title: 'news',
    href_en: 'https://ittihadclub.sa/news',
    href_ar: 'https://ittihadclub.sa/news',
    external: true,
  },
  {
    title: 'contact_us',
    href_en: 'https://ittihadclub.sa/contact-us',
    href_ar: 'https://ittihadclub.sa/contact-us',
    external: true,
  },
];
