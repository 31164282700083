import { useTranslation } from 'next-i18next';
import Image from 'next/image';

const regularSponsors = [
  '/images/sponsors/8.svg',
  '/images/sponsors/7.svg',
  '/images/sponsors/6.svg',
  '/images/sponsors/5.svg',
  '/images/sponsors/4.svg',
  '/images/sponsors/3.svg',
  '/images/sponsors/2.svg',
  '/images/sponsors/1.svg',
  '/images/sponsors/Jamjoom.jpg',
];
const platinumSponsors = ['/images/sponsors/10.svg', '/images/sponsors/9.svg'];

const Sponsors = () => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col items-center justify-center gap-4 text-center'>
      <p className='capitalize'>{t('common:sponsors.reg')}</p>
      <ul dir='rtl' className='grid grid-cols-2 justify-center gap-2'>
        {platinumSponsors.map((sponsor, index) => (
          <li key={index} className='grid place-items-center rounded-md bg-white'>
            <figure className='relative aspect-[3/2] w-28 md:min-w-32'>
              <Image
                src={sponsor}
                fill
                alt={sponsor}
                style={{ objectFit: 'contain' }}
                sizes='168px'
              />
            </figure>
          </li>
        ))}
      </ul>
      <ul dir='rtl' className='flex w-full max-w-lg justify-center gap-1 md:gap-2'>
        {regularSponsors.map((sponsor, index) => (
          <li
            key={index}
            className='grid aspect-[3/2] w-full place-items-center rounded-md bg-white md:min-h-14'
          >
            <figure className='relative h-full w-full'>
              <Image
                src={sponsor}
                fill
                alt={sponsor}
                style={{ objectFit: 'contain' }}
                sizes='168px'
              />
            </figure>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sponsors;
